import React, { useState, useEffect } from "react";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import CartPage from "./components/CartPage";
import Homepage from "./components/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import MaintenancePage from "./components/MaintenancePage"; // Import the MaintenancePage component
import { Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  const [cartCount, setCartCount] = useState<number>(0);
  const [isMaintenanceMode] = useState<boolean>(true); // Maintenance mode flag

  useEffect(() => {
    fetchCartCount();
  }, []);

  const fetchCartCount = () => {
    try {
      // Get cart data from local storage
      const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
      const count = cartData.length;
      setCartCount(count);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const updateCartCount = () => {
    fetchCartCount();
  };

  return (
    <div>
      {/* Conditionally render the NavBar, Footer, and content based on maintenance mode */}
      {isMaintenanceMode ? (
        <MaintenancePage />
      ) : (
        <>
          <NavBar cartCount={cartCount} />
          <Routes>
            <Route
              path="/"
              element={<Homepage updateCartCount={updateCartCount} />}
            />
            <Route
              path="/home"
              element={<Homepage updateCartCount={updateCartCount} />}
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route
              path="/cartpage"
              element={<CartPage updateCartCount={updateCartCount} />}
            />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
