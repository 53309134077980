import React from "react";

const MaintenancePage = () => {
  const containerStyle: React.CSSProperties = {
    textAlign: "center",
    marginTop: "120px",
    marginBottom: "50px",
    maxWidth: "960px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 15px",
  };

  const messageStyle: React.CSSProperties = {
    fontSize: "2rem",
    color: "#385d31",
  };

  const descriptionStyle: React.CSSProperties = {
    fontSize: "1.2rem",
    color: "#000",
    marginTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={messageStyle}>We are currently under maintenance</h1>
      <p style={descriptionStyle}>
        Sorry for the inconvenience. We are performing some maintenance at the moment.
        Please check back later.
      </p>
    </div>
  );
};

export default MaintenancePage;
